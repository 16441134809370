<template>
  <div class="page-title">学校の設定</div>
  <div class="school-btns">
    <div
      v-for="school in schools"
      :key="school.value"
      :class="{selected: isSelected(school)}"
      @click="selectSchool(school)"
      class="school-btn">
      {{school.text}}
      </div>
  </div>
  <div v-if="inProgress" class="btns-in-row mt-2">
    <button
      @click="back"
      class="btn-img">
      <img src="@/assets/icon-back.png"/>
    </button>
    <button
      @click="submit"
      :disabled="!selectedSchool"
      class="btn-img">
      <img src="@/assets/icon-next.png"/>
    </button>
  </div>
  <div v-else>
    <button
      @click="submit"
      :disabled="!selectedSchool"
      class="btn-bg mt-2">Finish</button>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SelectSchool',
  props: {
    inProgress: {
      type: Boolean,
      require: true,
    }
  },
  computed: {
    ...mapState(['configs',]),
    isSelected() {
      return school => school.value === this.selectedSchool
    }
  },
  data() {
    return {
      schools: [
        {value: 1, text: '小学校'},
        {value: 2, text: '中学校'},
        {value: 3, text: '高等学校'},
        {value: 4, text: 'その他'},
      ],
      selectedSchool: null,
    }
  },
  created() {
    this.selectedSchool = this.configs.school
  },
  methods: {
    selectSchool(school) {
      this.selectedSchool = school.value
    },
    submit() {
      this.$emit('submit', this.selectedSchool)
    },
    back() {
      this.$emit('back')
    }
  }
}
</script>

<style lang="scss" scoped>
.school-btns {
  padding-top: 80px;
  color: #707070;
  .school-btn {
    background: linear-gradient(to bottom, #28529D, #0E1D3A);
    color: white;
    padding: 5px 10px;
    margin: auto;
    border-radius: 5px;
    width: 300px;
    margin-bottom: 30px;
    cursor: pointer;
    font-size: 28px;
    font-weight: bold;
    border: 2px #28529D solid;
    box-shadow: 3px 3px 6px 1px rgb(80 80 80 / 30%);
  }
  .school-btn:hover {
    border: 2px rgb(29, 200, 29) solid;
    background: linear-gradient(to bottom, #789ee0, #5773a8);
  }
  .selected {
    background: linear-gradient(to bottom, #789ee0, #789ee0);
    border: 2px solid #789ee0;
  }
}
</style>

